import 'bootstrap/js/dist/carousel.js';
import Hammer from 'hammerjs';

/**
 * Base Bootstrap Carousel functionality.
 *
 * @requires bootstrap/carousel
 * @requires hammerjs
 * @memberof module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class CarouselBase {
    /**
     * Initializes carousel and basic settings.
     * @param {jQueryElement} $element jQueryDomElement which is upgraded with this functionality.
     */
    constructor($element) {
        this.$element = $($element);
        this.carousel = null;
        this.slides = [];
        this.gestures = null;
        this.buttons = null;
        this.directions = {
            LEFT: 2,
            RIGHT: 4,
        };

        this.config = {
            useSwipe: true,
        };
    }

    /**
     * Creates the carousel.
     */
    init($carouselElement) {
        // create carousel
        if (typeof $carouselElement === 'undefined') {
            this.carousel = this.$element.carousel();
        } else {
            this.carousel = $carouselElement.carousel();
        }

        if (this.config.useSwipe === true) {
            // add swipe device gestures via Hammer
            this.gestures = new Hammer.Manager(this.$element[0], {});
            this.gestures.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 }));
        }

        // get buttons
        this.buttons = {
            PREV: this.$element.find('[data-slide="prev"]'),
            NEXT: this.$element.find('[data-slide="next"]'),
        };

        // get slides
        this.slides = this.$element.find('.carousel-item');

        // events
        this.buttons.PREV.click((e) => {
            e.preventDefault();
            this.showPrev();
        });

        this.buttons.NEXT.click((e) => {
            e.preventDefault();
            this.showNext();
        });

        if (this.config.useSwipe === true) {
            this.gestures.on('swipe', (e) => {
                switch (e.direction) {
                    case this.directions.LEFT: {
                        this.showNext();
                        break;
                    }
                    case this.directions.RIGHT: {
                        this.showPrev();
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
        }
    }

    /**
     * Shows slide with index
     * @param {number} index Slide index.
     */
    showSlide(index) {
        this.carousel.carousel(index);
    }

    /**
     * Shows previous slide by calling BS event.
     */
    showPrev() {
        this.carousel.carousel('prev');
    }

    /**
     * Shows next slide by calling BS event.
     */
    showNext() {
        this.carousel.carousel('next');
    }
}

export default CarouselBase;

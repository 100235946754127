/**
 * All header functionality.
 *
 * @module feature/Header
 */
import lodash from 'lodash';
import CarouselBase from 'CarouselBase';

/**
 * Creates a Header Carousel based on Bootstrap default carousel.
 *
 * @requires lodash
 * @extends module:project/Common.CarouselBase
 * @memberof module:feature/Header
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class HeaderCarousel extends CarouselBase {

    /**
     * Sets up component.
     * @param {jQueryElement} $element DOM element to upgrade.
     */
    constructor($element) {
        super($element);
        this.$indicators = null;
        this.visualheight = 0;
    }

    /**
     * Initializes indicators and click events.
     */
    init() {

        super.init();

        this.$indicators = this.$element.find('.carousel-indicators');

        // if there are indicators
        if (this.$indicators.length > 0) {
            this.setVisualHeight();
            this.positionIndicators();

            // add click events
            this.$indicators.on('click', (e) => {
                e.preventDefault();
                if (!$(e.target).hasClass('active')) {
                    this.showSlide($(e.target).data('slide-to'));
                }
            });

            // also position indicators on item change
            this.$element.on('slid.bs.carousel', (e) => {
                this.setVisualHeight(e.to);
                this.positionIndicators();
            });

            // and on window resize
            $(window).on('resize.headercarousel', lodash.debounce(() => {
                this.setVisualHeight();
                this.positionIndicators();
            }, 200));
        }

    }

    /**
     * Sets visualheight property.
     * @param {number} [index = 0] Slide index
     */
    setVisualHeight(index=0) {
        this.visualHeight = parseInt($(this.slides[index]).find('.visual').first().css('height'));
    }

    /**
     * (Re-)positions carousel indicators.
     */
    positionIndicators() {
        if (viewport.is('>=lg')) {
            this.$indicators.css('top', this.visualHeight - 40 + 'px');
        } else {
            this.$indicators.css('top', this.visualHeight + 20 + 'px');
        }
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: HeaderCarousel,
    classAsString: 'HeaderCarousel',
    cssClass: 'js-header-carousel'
});

export default HeaderCarousel;